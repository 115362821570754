import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import Tabs from '../../shared/ui/navigation/Tabs';
import HOME_TABS from './constants';
import { ContainerColumn, NarrowSection } from '../../components/styled';
import { matchPathToHomeTab } from './utils';
import useAppNavigation from '../../shared/lib/hooks/useAppNavigation';
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    alignItems: 'center',
    backgroundColor: props.theme.customColors.surface.surface,
    gap: props.theme.spacing_sizes.l,
    paddingTop: props.theme.spacing_sizes.s * 5,
}));
const NarrowContainer = NarrowSection.withComponent(ContainerColumn);
const TabsStyled = styled((Tabs))((props) => ({
    minHeight: 'unset',
    '& .MuiTabs-flexContainer': {
        width: '100%',
        gap: props.theme.spacing_sizes.m,
    },
}));
const Home = () => {
    const location = useLocation();
    const { routes, navigate } = useAppNavigation();
    const currentTab = useMemo(() => matchPathToHomeTab(location.pathname), [location]);
    const onTabChange = (selectedTab) => {
        switch (selectedTab) {
            case 'my_profile':
                navigate(routes.MY_PROFILE);
                break;
            case 'overview':
                navigate(routes.HOME_OVERVIEW);
                break;
            case 'platform_balance':
                navigate(routes.HOME_BALANCE);
                break;
            default:
        }
    };
    useEffect(() => {
        if (!currentTab) {
            navigate(routes.MY_PROFILE, { replace: true });
        }
    }, [
        currentTab,
        routes,
        navigate,
    ]);
    if (!currentTab) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Container, { children: _jsx(NarrowContainer, { children: _jsx(TabsStyled, { tabs: HOME_TABS, activeTab: currentTab, onChange: onTabChange }) }) }), _jsx(Outlet, {})] }));
};
export default Home;
