import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
const OverviewTabTitle = () => Trans({ ns: 'common', i18nKey: 'pages.overview.overview' });
const PlatformBalanceTabTitle = () => Trans({ ns: 'common', i18nKey: 'pages.overview.balance' });
const MyProfileTabTitle = () => Trans({ ns: 'common', i18nKey: 'pages.my_profile' });
const HOME_TABS = [
    {
        label: _jsx(OverviewTabTitle, {}),
        value: 'overview',
    },
    {
        label: _jsx(PlatformBalanceTabTitle, {}),
        value: 'platform_balance',
    },
    {
        label: _jsx(MyProfileTabTitle, {}),
        value: 'my_profile',
    },
];
export default HOME_TABS;
